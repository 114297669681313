import React from 'react';
import './MainContent.css';
import mainImage from '../images/atf-landing-image-0328.jpg';
import multiWeekChallengeImage from '../images/shape-your-glutes.jpeg';

const MainContent = () => {
    return (
      <div className="main-content">
        <img src={mainImage} alt="Advanced Transformation Fitness trainer and client in gym" className="main-image"/>
        <p className="main-text">I HELP WOMEN REACH THEIR DREAM BODY WITHOUT GIVING UP THEIR SOCIAL LIFE.</p>
        <div className="main-guides">
          <a href="https://app.advancedtransformationfitness.com/register" className="link with-thumbnail" style={{ "--animation-delay": "1s" }}>
            <div className="thumbnail-wrapper">
              <img alt="8 WEEK Challenge" src={multiWeekChallengeImage}/>
            </div>
            <div className="price-text">$49.99</div>
          </a>
        </div>
      </div>
      );
};

export default MainContent;